.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  padding: 32px;
  position: relative;
  border-radius: 6px;
  background-color: #242E42;
  max-width: 700px;
  width: 67%;
  min-width: 365px; 
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}

.card-title {
  text-align: center;
  margin-top: 16px;
  color: white;
}
.card-subtitle {
  text-align: center;
  color: #A2B3D9;
  margin-bottom: 24px;
  padding: 0 32px;
  font-size: 24px;
}

.card-header-img {
  position: absolute;
  border-radius: 50%;
  left:50%; 
  transform: translate(-50%, -85px);
  text-align: center; 
  width: 100px;
  height: 100px;
  background: #242e42;
}

.card-header-img img {
  width: 58px; 
  margin-top: 15px;
}

.pulse-animation {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.09);
  }
  100% {
    transform: scale(1);
  }
}