@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#content {
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    background: #FFFFFF;
    width: 60vw;
    border-radius: 4px 0px 0px 4px;
    padding-left: 16px;
    color: #7C89A6;
    -moz-appearance:textfield;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    top: 0px;
    -webkit-appearance: none;
    font-family: Source Sans Pro;
    font-size: 28px;
    font-weight: 600;
    height: 69px;
    border: none;
    line-height: 36px;
}

input[type=submit] {
    background: #5E49FD;
    color: #FFFFFF;
    border-radius: 0px 4px 4px 0px;
    width: 120px;
    cursor: pointer;
}

#secondRow {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #EA6969;
    text-align: left;
    margin-top: 15px;
}

#secondJoinRow {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #7C89A6;
    text-align: left;
    margin-top: 15px;
}

h3 {
    color: #FFFFFF;;
    text-align: left;
    font-weight: 600;
    margin-bottom: 34px;
    font-size: 35px;
}

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  padding: 32px;
  position: relative;
  border-radius: 6px;
  background-color: #242E42;
  max-width: 700px;
  width: 67%;
  min-width: 365px; 
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}

.card-title {
  text-align: center;
  margin-top: 16px;
  color: white;
}
.card-subtitle {
  text-align: center;
  color: #A2B3D9;
  margin-bottom: 24px;
  padding: 0 32px;
  font-size: 24px;
}

.card-header-img {
  position: absolute;
  border-radius: 50%;
  left:50%; 
  transform: translate(-50%, -85px);
  text-align: center; 
  width: 100px;
  height: 100px;
  background: #242e42;
}

.card-header-img img {
  width: 58px; 
  margin-top: 15px;
}

.pulse-animation {
  -webkit-animation: pulse 3s infinite;
          animation: pulse 3s infinite;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.09);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.09);
  }
  100% {
    transform: scale(1);
  }
}
#container {
    width: 335px;
}

#circle {
    display: flex;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    background-color: rgba(234, 105, 105, 0.2);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 23px 15px 23px 15px;
}

#thinking {
    width: 26px;
    height: 26px;
}

#logo {
    margin: 0px -13px 20px -13px;
}

#msgBox {
    box-shadow: 0px 0px 30px 10px rgba(11, 14, 21, 0.25);
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: left;
    background-color: rgba(234, 105, 105, 0.2);
    border-radius: 7.18367px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #EA6969;
    margin-bottom: 20px;
}


#footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

#firstRow {
    display: flex;
    text-align: left;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

#appstore {
    height: 43px;
    max-width: 133px;
    padding-bottom: 20px;
    padding-top: 10px;
}

#googleplay{
    height: 43px;
    max-width: 152px;
    padding-bottom: 20px;
    padding-top: 10px;
}

#msgBox2 {
    box-shadow: 0px 0px 30px 10px rgba(11, 14, 21, 0.25);
    background-color: rgba(52, 190, 126, 0.2);
    border-radius: 7.18367px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #34BE7E;
}

.App {
  text-align: center;
  background-color: #1C1D2A;
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

